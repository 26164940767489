import "./App.css";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-scroll";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import skyline from "./images/skyline.jpeg";

import projects from "./projects.json";
import { Github, Linkedin, Envelope, PlayFill } from "react-bootstrap-icons";

import {
  FaFileAlt,
  FaPenFancy,
  FaPenNib,
  FaSpellCheck,
  FaBook,
  FaComments,
  FaUsers,
  FaClock,
  FaTasks,
} from "react-icons/fa";

function App() {
  const skills = [
    {
      icon: <FaFileAlt size={64} />,
      name: "Grant Writing & Proposal Development",
    },
    { icon: <FaPenFancy size={64} />, name: "Creative Writing" },
    { icon: <FaPenNib size={64} />, name: "Professional Writing" },
    { icon: <FaSpellCheck size={64} />, name: "Copyediting & Proofwriting" },
    { icon: <FaBook size={64} />, name: "Research & Documentation" },
    {
      icon: <FaComments size={64} />,
      name: "Strong Written & Verbal Communication",
    },
    {
      icon: <FaUsers size={64} />,
      name: "Team Collaboration & Project Coordination",
    },
    { icon: <FaClock size={64} />, name: "Deadline Management" },
    { icon: <FaTasks size={64} />, name: "Organization & Attention to Detail" },
  ];
  return (
    <div className="App">
      <div className="banner" style={{ backgroundImage: `url(${skyline})` }}>
        <div className="banner-overlay">
          <Navbar expand="lg" className="navbar" variant="dark">
            <Container>
              <div className="navbar-brand">Samantha Hojnacki</div>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="justify-content-end navbar-collapse"
              >
                <Nav className="ml-auto">
                  <Link
                    activeClass="active"
                    to="about"
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="nav-link"
                  >
                    About
                  </Link>
                  <Link
                    activeClass="active"
                    to="skills"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="nav-link"
                  >
                    Skills
                  </Link>
                  <Link
                    activeClass="active"
                    to="projects"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="nav-link"
                  >
                    Projects
                  </Link>
                  <Link
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="nav-link"
                  >
                    Contact
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div className="banner-content">
            <h1 className="banner-title">Welcome to my portfolio</h1>
          </div>
        </div>
      </div>
      <section id="about">
        <Container className="mt-3">
          <h1 className="mb-4">About Me</h1>
          <p>
            I am a recent Bradley University graduate with an English degree, a
            Marketing minor, and a triple concentration in Professional Writing,
            Creative Writing, and Literary Studies. I am currently searching for
            a position that will challenge my current writing abilities. I have
            demonstrated experience writing professionally and creatively
            throughout my time as an undergraduate student and look forward to
            expanding my skillsets.
          </p>
        </Container>
      </section>
      <section id="skills">
        <Container className="mt-3">
          <Row>
            <h1 className="mb-5">My Skills</h1>
            {skills.map((skill, index) => (
              <Col
                sm={12}
                md={6}
                lg={4}
                key={index}
                className="text-center mb-4"
              >
                <div className="d-flex justify-content-center">
                  {skill.icon}
                </div>
                <p>{skill.name}</p>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section id="projects">
        <Container className="mt-3">
          <h1 className="mb-4">My Projects</h1>
          <Row>
            {projects.map((project) => (
              <Col sm={12} md={6} lg={4} key={project.id}>
                <div className="d-flex justify-content-center">
                  <div className="Project">
                    <Card className="project-card">
                      <h2 style={{ color: "white" }}>{project.name}</h2>
                      <Card.Body className="project-card-body">
                        {project.description}
                      </Card.Body>
                      <div className="mb-3 d-flex justify-content-around">
                        <Button
                          href={project.liveLink}
                          className="project-card-button mr-2"
                        >
                          <PlayFill size={32} />
                        </Button>
                      </div>
                    </Card>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section id="contact">
        <Container className="mt-3">
          <Card className="text-center contact-card mb-3">
            <Card.Header>
              <h2 style={{ color: "white" }}>Contact Me</h2>
            </Card.Header>
            <Card.Body>
              <Card.Title>Samantha Hojnacki</Card.Title>
              <Card.Text>
                <p>Email: samantha_hojnacki@yahoo.com</p>
                <p>Phone: (815)545-4213</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </section>
      <footer className="MyFooter">
        <div className="container">
          <ul className="footer-links">
            <li>
              <a
                href="https://www.linkedin.com/in/samantha-hojnacki-894027224/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin size={32} />
              </a>
            </li>
            <li>
              <a href="mailto:samantha_hojnacki@yahoo.com">
                <Envelope size={32} />
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default App;
